import { Button } from "@mui/material";
import React, { Component } from "react";
import { useSetRecoilState } from "recoil";
import { useIndexedDB } from "react-indexed-db";
import { notesListState } from "../../store/atoms/notes_list";
import styled from "styled-components";

const NoteItem = (props: any) => {
  const db = useIndexedDB("notes");
  const setNotesList = useSetRecoilState(notesListState);
  const note = props.note;

  const handleDeleteCLick = () => {
    console.log("handleDeleteCLick");
    db.deleteRecord(note.id).then(() => {
      console.log("note deleted");
      db.getAll().then((items: any) => {
        console.log("items", items);
        setNotesList(items);
      });
    });
  };

  const handleEditCLick = () => {
    console.log("handleEditCLick");
  };

  let content = (
    <Div>
      <div>{note.name}</div>
      <ButtonEdit variant="outlined" onClick={handleEditCLick}>
        Edit
      </ButtonEdit>
      <ButtonDelete variant="outlined" onClick={handleDeleteCLick}>
        Delete
      </ButtonDelete>
    </Div>
  );
  return content;
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
`;

const ButtonEdit = styled(Button)`
  &&& {
    margin-left: 1.5rem;
  }
`;

const ButtonDelete = styled(Button)`
  &&& {
    margin-left: 2rem;
  }
`;

export default NoteItem;
