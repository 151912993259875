import React, { Component, useRef } from "react";
import Button from "@mui/material/Button";
import { useRecoilState, useSetRecoilState } from "recoil";
import { recoilSwitchState } from "../../store/atoms/recoil_switch";
import { notesListState } from "../../store/atoms/notes_list";
import { useIndexedDB } from "react-indexed-db";
import { Input } from "@mui/material";
import styled from "styled-components";

const Navigation = () => {
  const db = useIndexedDB("notes");
  const nameRef = useRef<HTMLInputElement>();
  const setNotesList = useSetRecoilState(notesListState);

  const [recoilSwitch, setRecoilSwitchSwitch] =
    useRecoilState(recoilSwitchState);
  const clickHandle = () => {
    if (
      typeof nameRef.current !== "undefined" &&
      typeof nameRef.current["value"] !== "undefined"
    ) {
      const name = nameRef.current["value"];
      db.add({ name, list: [] }).then(() => {
        console.log("Uspesno dodavanje u indexeddb");
        if (nameRef.current) {
          nameRef.current.value = "";
        }
        // nameRef.current["value"] = "";
        db.getAll().then((items: any) => {
          console.log("items", items);
          setNotesList(items);
        });
      });
    }
    // setRecoilSwitchSwitch(!recoilSwitch);
  };
  let content = (
    <Div>
      <Input placeholder="name" inputRef={nameRef} />
      <ButtonAdd variant="contained" onClick={clickHandle}>
        Add List
      </ButtonAdd>
    </Div>
  );
  return content;
};

const Div = styled.div`
  margin-top: 1rem;
`;

const ButtonAdd = styled(Button)`
  &&& {
    margin-left: 1rem;
  }
`;

export default Navigation;
