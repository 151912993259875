import React, { Component } from "react";
import styled from "styled-components";

const MainHeader = () => {
  let content = <Div>Paker</Div>;
  return content;
};

const Div = styled.div`
  font-size: 2rem;
`;

export default MainHeader;
