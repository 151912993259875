import React, { Component } from "react";
import { useRecoilValue } from "recoil";
import { notesListState } from "../../store/atoms/notes_list";
import NoteItem from "./NoteItem";
import { styled } from "styled-components";

const NotesList = () => {
  const notesList = useRecoilValue(notesListState);

  const list = notesList.map((note, ind) => <NoteItem key={ind} note={note} />);
  let content = <Div>{list}</Div>;
  return content;
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export default NotesList;
