import React, { Component } from "react";
import "./App.css";
import { RecoilRoot } from "recoil";
import MainContent from "./layout/MainContent";
import MainHeader from "./layout/MainHeader";
import Navigation from "./navigation/Navigation";
import { DBConfig } from "../indexeddb/DBConfig";
import { initDB } from "react-indexed-db";
import { styled } from "styled-components";

initDB(DBConfig);

function App() {
  return (
    <RecoilRoot>
      <Div>
        <MainHeader />
        <Navigation />
        <MainContent />
      </Div>
    </RecoilRoot>
  );
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 1rem;
`;

export default App;
