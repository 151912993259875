import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useIndexedDB } from "react-indexed-db";
import NotesList from "../notes/NotesList";
import { notesListState } from "../../store/atoms/notes_list";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const MainContent = () => {
  const db = useIndexedDB("notes");
  const setNotesList = useSetRecoilState<any>(notesListState);
  useEffect(() => {
    db.getAll().then((items) => {
      console.log("items", items);
      setNotesList(items);
    });
  }, []);

  let content = (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<NotesList />} />
          <Route path="/list" element={<NotesList />} />
        </Routes>
      </Router>
    </div>
  );
  return content;
};

export default MainContent;
